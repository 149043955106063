import React from "react"
import software from "../../assets/images/banners/solutions-images/software.jpg"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const Software = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img software"
          data-bg=""
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Software Solutions</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto has a track record for developing dependable and
                    scalable corporate solutions that will add value to your
                    organization. Our skilled engineers use a combination of
                    cutting-edge technologies that provide future-proof
                    solutions and services to assist you in achieving your
                    business objectives.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Solution</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Software <span className="text-color-primary">Solutions</span></h2>
                  We provide a wide range of custom enterprise
                  technology solutions to match your company's needs and
                  deliver rapid and efficient results.
                  <div className="mt-20 title-wrap">
                    <div className="title-wrap">
                      <p className="title" style={{ marginBottom: "0px" }}>
                        <i className="fas fa-check"></i> Requirement Gathering
                      </p>
                      <p className="title" style={{ marginBottom: "0px" }}>
                        <i className="fas fa-check"></i> Enterprise Mobility Services
                      </p>
                      <p className="title" style={{ marginBottom: "0px" }}>
                        <i className="fas fa-check"></i> Legacy Application Modernization
                      </p>
                      <p className="title" style={{ marginBottom: "0px" }}>
                        <i className="fas fa-check"></i> Digital Transformation Services
                      </p>
                      <p className="title" style={{ marginBottom: "0px" }}>
                        <i className="fas fa-check"></i> Data Management
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={software} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Software Development <span className="text-color-primary">Offered by our Team</span></h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Team Augmentation</h5>
                      <div className="desc">
                        Our technical team applies cutting-edge expertise and
                        skills to fill your technical skill gaps and bring your
                        project to life.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Software Product Development </h5>
                      <div className="desc">
                        Our services include but are not limited to complete
                        product lifecycle management, enterprise application
                        integration solutions, business intelligence and
                        analytics, technology migration and others.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">
                        Software Development Consulting{" "}
                      </h5>
                      <div className="desc">
                        Our goal is to improve your business efficiency by
                        planning, prioritizing and guiding your digital
                        transformation initiatives.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Custom Software Development</h5>
                      <div className="desc">
                        With the right understanding of your needs and the
                        competition, we create products that are not only
                        powerful but also make you stand out. Obtain a
                        competitive advantage by implementing our solutions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}
export default Software
