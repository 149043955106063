import * as React from "react"
import Layout from "../components/layout"
import Software from "../components/Solutions/Software"






const software = () => {
  return (
  <Layout>
   <Software/>
  </Layout>
)
};
export default software;
